/** Aurora (Borealis) light variant theme imports **/

body,
body.light-theme,
.light-theme {

    [data-theme="dark"] {
        display: none;
    }

    [data-theme="light"] {
        display: block;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    div,
    span,
    a {
        color: $black-c1c;
    }
}
