.dao-modal {

    .modal-buttons {

        .btn-primary * {
            // Goes to original bootstrap coloring - this is used to override the aurora custom rule
            color: unset;
        }

        .btn-secondary:hover * {
            // Goes to original bootstrap coloring - this is used to override the aurora custom rule
            color: unset;
        }


    }
}