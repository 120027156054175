h1,
.header-1 {
    font-size: 2rem;
    font-weight: bold;
    line-height: 2.25rem;
    /* 112.5% */
}

h2,
.header-2 {
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 2.25rem;
    /* 112.5% */
}

h3,
.header-3 {
    font-size: 1.125rem;
    font-weight: bold;
    line-height: 110%;
    /* 19.8px */
}

h5,
.header-5 {
    font-size: 1.125rem;
    font-weight: 600;
    line-height: 110%;
    /* 19.8px */
}
