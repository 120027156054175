footer {
    .disclosure {
        color: $black-c1c;
        font-size: .875rem;
    }

    a { 
        span:hover{
            color: $blue-099;
        }

        i:hover {
            color: $blue-099;
        }
    }
    
    .static-footer a {
        color: $black-c1c;
    }
    
    .static-footer-bottom {
        a {
            color: $black-c1c;
        }

        a:hover {
            color: $blue-099;
        }
    }
}

