/** Aurora (Borealis) variant theme imports **/
$red-f48: #F26F48;
$red-f3d: #F15F3D;
$yellow-415: #FCB415;
$yellow-10e: #FFC10E;
$green-652: #69A652;
$green-150: #77B150;
$green-fdc: #E4EFDC; // green-150 but 40% lighter
$blue-c8f: #3E9C8F;
$blue-09a: #00909A;
$blue-7d5: #00B7D5;
$blue-bc5: #00ABC5;

// 2024 design refresh - darker background colors for light text
$green-420: #428420;
$purple-88b: #8A488B;
$blue-099: #008099;
$red-336: #C35336;
$blue-669: #0E5669;
$red-322: #BC4322;
$green-d2a: #374D2A;
$purple-c43: #431C43;
$green-742: #557742;
$purple-f70: #6F3F70;

// 2024 design refresh - neutral colors
$white-fff: #FFFFFF;
$white-1ee: #F3F1EE;
$black-c1c: #1C1C1C;

.color {
    &-red {
        &-f48 {
            color: $red-f48 !important;
        }

        &-f3d {
            color: $red-f3d !important;
        }

        &-336 {
            color: $red-336 !important;
        }

        &-322 {
            color: $red-322 !important;
        }

    }

    &-yellow {
        &-415 {
            color: $yellow-415 !important;
        }

        &-10e {
            color: $yellow-10e !important;
        }

    }

    &-green {
        &-652 {
            color: $green-652 !important;
        }

        &-150 {
            color: $green-150 !important;
        }

        &-fdc {
            color: $green-fdc !important;
        }

        &-420 {
            color: $green-420 !important;
        }

        &-742 {
            color: $green-742 !important;
        }

        &-d2a {
            color: $green-d2a !important;
        }

    }

    &-blue {
        &-c8f {
            color: $blue-c8f !important;
        }

        &-7d5 {
            color: $blue-7d5 !important;
        }

        &-09a {
            color: $blue-09a !important;
        }

        &-bc5 {
            color: $blue-bc5 !important;
        }

        &-099 {
            color: $blue-099 !important;
        }

        &-669 {
            color: $blue-669 !important;
        }

    }

    &-purple,
    &-grimace {
        &-88b {
            color: $purple-88b !important;
        }

        &-c43 {
            color: $purple-c43 !important;
        }

        &-f70 {
            color: $purple-f70 !important;
        }

    }

    &-white {
        &-1ee {
            color: $white-1ee !important;
        }

        &-fff {
            color: $white-fff !important;
        }

    }

    &-black {
        &-c1c {
            color: $black-c1c !important;
        }

    }
}

.bkgcolor {
    &-red {
        &-f48 {
            background-color: $red-f48 !important;
        }

        &-f3d {
            background-color: $red-f3d !important;
        }

        &-336 {
            background-color: $red-336 !important;
        }

        &-322 {
            background-color: $red-322 !important;
        }

    }

    &-yellow {
        &-415 {
            background-color: $yellow-415 !important;
        }

        &-10e {
            background-color: $yellow-10e !important;
        }

    }

    &-green {
        &-652 {
            background-color: $green-652 !important;
        }

        &-150 {
            background-color: $green-150 !important;
        }
        &-fdc {
            background-color: $green-fdc !important;
        }

        &-fdc {
            background-color: $green-fdc !important;
        }

        &-420 {
            background-color: $green-420 !important;
        }

        &-742 {
            background-color: $green-742 !important;
        }

        &-d2a {
            background-color: $green-d2a !important;
        }

    }

    &-blue {
        &-c8f {
            background-color: $blue-c8f !important;
        }

        &-7d5 {
            background-color: $blue-7d5 !important;
        }

        &-09a {
            background-color: $blue-09a !important;
        }

        &-bc5 {
            background-color: $blue-bc5 !important;
        }

        &-099 {
            background-color: $blue-099 !important;
        }

        &-669 {
            background-color: $blue-669 !important;
        }

    }

    &-purple,
    &-grimace {
        &-88b {
            background-color: $purple-88b !important;
        }

        &-c43 {
            background-color: $purple-c43 !important;
        }

        &-f70 {
            background-color: $purple-f70 !important;
        }

    }

    &-white {
        &-1ee {
            background-color: $white-1ee !important;
        }

        &-fff {
            background-color: $white-fff !important;
        }

    }

    &-black {
        &-c1c {
            background-color: $black-c1c !important;
        }

    }
}