$title-font: Valizas;
$body-font: Recife;
$cta-font: Spezia;

/** Recife **/
@font-face {
  font-family: 'Recife';
  src: url('~Fonts/recife/RecifeTextWeb-Light.woff2') format('woff2'),
    url('~Fonts/recife/RecifeTextWeb-Light.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Recife';
  src: url('~Fonts/recife/RecifeTextWeb-LightItalic.woff2') format('woff2'),
    url('~Fonts/recife/RecifeTextWeb-LightItalic.woff') format('woff');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Recife';
  src: url('~Fonts/recife/RecifeTextWeb-Regular.woff2') format('woff2'),
    url('~Fonts/recife/RecifeTextWeb-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Recife';
  src: url('~Fonts/recife/RecifeTextWeb-RegularItalic.woff2') format('woff2'),
    url('~Fonts/recife/RecifeTextWeb-RegularItalic.woff') format('woff');
  font-weight: 400;
  font-style: italic;
}


@font-face {
  font-family: 'Recife';
  src: url('~Fonts/recife/RecifeTextWeb-SemiBold.woff2') format('woff2'),
    url('~Fonts/recife/RecifeTextWeb-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Recife';
  src: url('~Fonts/recife/RecifeTextWeb-SemiBoldItalic.woff2') format('woff2'),
    url('~Fonts/recife/RecifeTextWeb-SemiBoldItalic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Recife';
  src: url('~Fonts/recife/RecifeTextWeb-Bold.woff2') format('woff2'),
    url('~Fonts/recife/RecifeTextWeb-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Recife';
  src: url('~Fonts/recife/RecifeTextWeb-BoldItalic.woff2') format('woff2'),
    url('~Fonts/recife/RecifeTextWeb-BoldItalic.woff') format('woff');
  font-weight: 700;
  font-style: italic;
}

/** Spezia **/

@font-face {
  font-family: 'Spezia';
  src: url('~Fonts/spezia/SpeziaWeb-Light.woff2') format('woff2'),
    url('~Fonts/spezia/SpeziaWeb-Light.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Spezia';
  src: url('~Fonts/spezia/SpeziaWeb-LightItalic.woff2') format('woff2'),
    url('~Fonts/spezia/SpeziaWeb-LightItalic.woff') format('woff');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Spezia';
  src: url('~Fonts/spezia/SpeziaWeb-Regular.woff2') format('woff2'),
    url('~Fonts/spezia/SpeziaWeb-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Spezia';
  src: url('~Fonts/spezia/SpeziaWeb-RegularItalic.woff2') format('woff2'),
    url('~Fonts/spezia/SpeziaWeb-RegularItalic.woff') format('woff');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Spezia';
  src: url('~Fonts/spezia/SpeziaWeb-SemiBoldItalic.woff2') format('woff2'),
    url('~Fonts/spezia/SpeziaWeb-SemiBoldItalic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Spezia';
  src: url('~Fonts/spezia/SpeziaWeb-Bold.woff2') format('woff2'),
    url('~Fonts/spezia/SpeziaWeb-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Spezia';
  src: url('~Fonts/spezia/SpeziaWeb-BoldItalic.woff2') format('woff2'),
    url('~Fonts/spezia/SpeziaWeb-BoldItalic.woff') format('woff');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Spezia';
  src: url('~Fonts/spezia/SpeziaWeb-SemiBold.woff2') format('woff2'),
    url('~Fonts/spezia/SpeziaWeb-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

/** Spezia Extended **/
@font-face {
  font-family: 'Spezia Extended';
  src: url('~Fonts/spezia/SpeziaExtendedWeb-Medium.woff2') format('woff2'),
    url('~Fonts/spezia/SpeziaExtendedWeb-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Spezia Extended';
  src: url('~Fonts/spezia/SpeziaExtendedWeb-SemiBold.woff2') format('woff2'),
    url('~Fonts/spezia/SpeziaExtendedWeb-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Spezia Extended';
  src: url('~Fonts/spezia/SpeziaExtendedWeb-Bold.woff2') format('woff2'),
    url('~Fonts/spezia/SpeziaExtendedWeb-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

/** Velizas **/
@font-face {
  font-family: 'Valizas';
  src: url('~Fonts/valizas/ValizasFrostWeb-Light.woff2') format('woff2'),
    url('~Fonts/valizas/ValizasFrostWeb-Light.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Valizas';
  src: url('~Fonts/valizas/ValizasFrostWeb-LightItalic.woff2') format('woff2'),
    url('~Fonts/valizas/ValizasFrostWeb-LightItalic.woff') format('woff');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Valizas';
  src: url('~Fonts/valizas/ValizasFrostWeb-Regular.woff2') format('woff2'),
    url('~Fonts/valizas/ValizasFrostWeb-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Valizas';
  src: url('~Fonts/valizas/ValizasFrostWeb-RegularItalic.woff2') format('woff2'),
    url('~Fonts/valizas/ValizasFrostWeb-RegularItalic.woff') format('woff');
  font-weight: 400;
  font-style: italic;
}


@font-face {
  font-family: 'Valizas';
  src: url('~Fonts/valizas/ValizasFrostWeb-SemiBold.woff2') format('woff2'),
    url('~Fonts/valizas/ValizasFrostWeb-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Valizas';
  src: url('~Fonts/valizas/ValizasFrostWeb-SemiBoldItalic.woff2') format('woff2'),
    url('~Fonts/valizas/ValizasFrostWeb-SemiBoldItalic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Valizas';
  src: url('~Fonts/valizas/ValizasFrostWeb-Bold.woff2') format('woff2'),
    url('~Fonts/valizas/ValizasFrostWeb-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Valizas';
  src: url('~Fonts/valizas/ValizasFrostWeb-BoldItalic.woff2') format('woff2'),
    url('~Fonts/valizas/ValizasFrostWeb-BoldItalic.woff') format('woff');
  font-weight: 700;
  font-style: italic;
}

body {
  font-family: Recife, Georgia, serif;
  color: $black-c1c;
}

title, h1, h2, h3, h4, h5, h6, .header-1, .header-2, .header-3, .header-4, .header-5, .header-6 {
  font-family: Valizas;
}

p, a {
  line-height: 150%;
}

.spezia-extended {
  font-family: Spezia Extended;
}

.valizas {
  font-family: Valizas;
}

.btn, .cta {
  font-family: Spezia Extended;
  font-weight: 600;
}

.font-weight-bold {
  font-weight: bold;
}

.font-weight-semibold {
  font-weight: 600;
}

.font-weight-normal {
  font-weight: normal;
}

.font-weight-light {
  font-weight: 400;
}

.spezia, table {
  font-family: Spezia, Verdana, sans-serif;
}