/** This file overrides the default bootstrap variables and css variables to create a custom button **/

/** Universal & light theme button **/

$btn-border-radius: .25rem;
$btn-font-weight: 700;
$btn-line-height: 1.375rem;
$btn-font-size: 1rem;


.btn {
    --bs-btn-font-family: #{$cta-font};
    --bs-btn-padding-x: 1rem;
    --bs-btn-padding-y: 1rem;
    --bs-btn-border-width: 0.125rem;
}

.btn-primary {
    --bs-btn-bg: #{$black-c1c};
    --bs-btn-color: #{$white-fff};
    --bs-btn-border-color: #{$black-c1c};
    --bs-btn-hover-bg: #{$white-fff};
    --bs-btn-hover-color: #{$black-c1c};
    --bs-btn-hover-border-color: #{$black-c1c};
    --bs-btn-active-bg: #{$white-fff};
    --bs-btn-active-color: #{$black-c1c};
    --bs-btn-active-border-color: #{$black-c1c};
    --bs-btn-disabled-color: #333F48;
    --bs-btn-disabled-bg: #e0e0e0;
    --bs-btn-disabled-border-color: #e0e0e0;
}


.btn-secondary {
    --bs-btn-bg: transparent;
    --bs-btn-color: #{$black-c1c};
    --bs-btn-border-color: #{$black-c1c};
    --bs-btn-hover-bg: #{$black-c1c};
    --bs-btn-hover-color: #{$white-fff};
    --bs-btn-hover-border-color: #{$black-c1c};
    --bs-btn-active-bg: #{$black-c1c};
    --bs-btn-active-color: #{$white-fff};
    --bs-btn-active-border-color: #{$black-c1c};
}

/** Dark theme changes for the buttons **/

.dark-theme {
    .btn-primary {
        --bs-btn-bg: #{$white-fff};
        --bs-btn-color: #{$black-c1c};
        --bs-btn-border-color: #{$white-fff};
        --bs-btn-hover-bg: #{$black-c1c};
        --bs-btn-hover-color: #{$white-fff};
        --bs-btn-hover-border-color: #{$black-c1c};
        --bs-btn-active-bg: #{$black-c1c};
        --bs-btn-active-color: #{$white-fff};
        --bs-btn-active-border-color: #{$black-c1c};
    }

    .btn-secondary {
        --bs-btn-bg: transparent;
        --bs-btn-color: #{$white-fff};
        --bs-btn-border-color: #{$white-fff};
        --bs-btn-hover-bg: #{$white-fff};
        --bs-btn-hover-color: #{$black-c1c};
        --bs-btn-hover-border-color: #{$white-fff};
        --bs-btn-active-bg: #{$white-fff};
        --bs-btn-active-color: #{$black-c1c};
        --bs-btn-active-border-color: #{$white-fff};
    }

}