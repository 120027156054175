.nav-link {
    --bs-nav-link-color: #{$black-c1c};
    --bs-nav-link-hover-color: #{$blue-099};
    font-family: Valizas;

    span:hover,
    span:active {
        color: inherit;
        border-bottom: 3px solid $blue-099;
    }
}



.top-nav {
    .dropdown-menu.page-dropdown {
        
        .dropdown-pagelink-header a, span, p {
            font-family: Spezia Extended;
            font-weight: bold;
        }

        .dropdown-pagelink a {
            font-family: Spezia Extended;
            font-weight: 600;

            &:hover {
                color: $blue-099;
            }
        }
    }

    .dropdown-toggle.show>span {
        border-bottom: 3px solid $blue-099;
        padding-bottom: 1.71rem;
    }
}