/** Aurora (Borealis) dark variant theme imports **/

body.dark-theme,
.dark-theme{
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    span,
    p{
        color: $white-fff;
    }

    [data-theme="dark"] {
        display: block;
    }
    
    [data-theme="light"] {
        display: none;
    }
}